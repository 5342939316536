import React from 'react';
import './AboutPage.css'; // Import CSS for styling the page

function AboutPage() {
    return (
        <div className="about-page" style={{ overflowY: 'scroll', height: '100vh' }}>
            {/* Company Overview */}
            <section className="company-overview">
                <h2>About Blaafunn</h2>
                <p>
                Blaafunn links people to buildings. Connecting business, art, real estate and technology. 
                Our mission is for buildings to be be re-energized as a platform for people and ideas.  
                </p>
            </section>
            {/* Contact Information */}
            <section className="contact-information">
                <h3>Contact</h3>
                <p>
                    Want to reach out? Here's how you can contact us:
                    <br />
                    Email: <a href="mailto:post@blaafunn.no">post@blaafunn.no</a>
                </p>
            </section>
        </div>
    );
}

export default AboutPage;
