import { Html, useProgress } from '@react-three/drei';

function Loader() {
  const { progress } = useProgress();
  return (
    <Html fullscreen>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'slategray',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '300px',
            height: '30px',
            border: '1px solid black',
            borderRadius: '5px',
            
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              width: `${progress}%`,
              height: '100%',
              backgroundColor: 'black',
            }}
          />
        </div>
        <p style={{ color: 'black', fontSize: '18px' }}>{progress.toFixed(0)}% Loaded</p>
      </div>
    </Html>
  );
}

export default Loader;
