import { useRef, useState, Suspense} from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { SoftShadows, Float, CameraControls, Sky, PerformanceMonitor } from "@react-three/drei"
import { useControls } from "leva"
import { Perf } from "r3f-perf"
import { easing } from "maath"
import { Model as Room } from "./Room"
import * as THREE from 'three';
import Loader from './Loader';
import NavigationMenu from './components/NavigationMenu'; // Import the dropdown
import './App.css'; // Ensure styles are imported
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './pages/AboutPage'; // Import the new About page
import ContactPage from './pages/ContactPage.jsx'; // Existing Contact page


function Light() {
  const ref = useRef()
  useFrame((state, delta) => {
    easing.dampE(ref.current.rotation, [(state.pointer.y * Math.PI) / 50, (state.pointer.x * Math.PI) / 20, 0], 0.2, delta)
  })

  
  return (
    
    <group ref={ref}>
      <directionalLight position={[5, 5, -8]} castShadow intensity={5} shadow-mapSize={2048} shadow-bias={-0.001}>
        <orthographicCamera attach="shadow-camera" args={[-8.5, 8.5, 8.5, -8.5, 0.1, 20]} />
      </directionalLight>
    </group>
  )
}

export default function App() {
  const [bad, set] = useState(false)
  const { impl, debug, enabled, samples, ...config } = useControls({
    debug: false,
    enabled: true,
    size: { value: 35, min: 0, max: 100, step: 0.1 },
    focus: { value: 0.5, min: 0, max: 2, step: 0.1 },
    samples: { value: 16, min: 1, max: 40, step: 1 }

  })
  return (
    <Router>
      <div className="app-container">
        <NavigationMenu />

        <Routes>
          <Route
            path="/"
            element={
    
    <Canvas shadows camera={{ position: [5, 2, 10], fov: 50 }}>
          <Suspense fallback={<Loader />}>
      {debug && <Perf position="top-left" />}
      <PerformanceMonitor onDecline={() => set(true)} />
      {enabled && <SoftShadows {...config} samples={bad ? Math.min(6, samples) : samples} />}
      <CameraControls
          makeDefault
          minAzimuthAngle={THREE.MathUtils.degToRad(-20)}
          maxAzimuthAngle={THREE.MathUtils.degToRad(30)}
          minPolarAngle={THREE.MathUtils.degToRad(50)}
          maxPolarAngle={THREE.MathUtils.degToRad(90)}
          minDistance={5}
          maxDistance={10}
          enablePan={false}
          enableDamping={true}
          dampingFactor={0.05}
          initialPosition={[5, 2, 10]}
        />
      <color attach="background" args={["#d0d0d0"]} />
      <fog attach="fog" args={["#d0d0d0", 8, 35]} />
      <ambientLight intensity={0.4} />
      <Light />
      <Room scale={0.5} position={[0, -1, 0]} />
      <Sphere />
      <Sphere position={[2, 4, -8]} scale={0.9} />
      <Sphere position={[-2, 2, -8]} scale={0.8} />
      <Sky inclination={0.52} scale={20} />
      </Suspense>
    </Canvas>
    
   }
    />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

function Sphere({ color = "hotpink", floatIntensity = 15, position = [0, 5, -8], scale = 1 }) {
  return (
    <Float floatIntensity={floatIntensity}>
      <mesh castShadow position={position} scale={scale}>
        <sphereGeometry />
        <meshBasicMaterial color={color} roughness={1} />
      </mesh>
    </Float>
  )
}
