import React, { useState } from 'react';
import './ContactPage.css'; // Import CSS for styling the page

function ContactPage() {
  // State to handle form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Function to handle form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
    alert('Thank you for contacting us!');
  };

  return (
    <div className="contact-page">
      {/* Contact Information */}
      <section className="contact-info">
        <h2>Contact Us</h2>
        <p>If you have any questions or would like to learn more about Blåfunn, feel free to reach out to us using the contact form below.</p>
        <ul>
         { /*<li><strong>Email:</strong> <a href="mailto:contact@blaafunn.no">contact@blaafunn.no</a></li>
          <li><strong>Phone:</strong> +47 123 45 678</li>
          <li><strong>Office Location:</strong> 1234 Oslo Street, Oslo, Norway</li>*/}
        </ul>
      </section>

      {/* Contact Form */}
      <section className="contact-form-section">
        <h3>Send Us a Message</h3>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Your Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="5"
              required
            />
          </div>
          <button type="submit" className="submit-button">Send Message</button>
        </form>
      </section>
    </div>
  );
}

export default ContactPage;
