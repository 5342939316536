import React from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


export function NavigationMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="absolute" style={{ background: 'transparent', boxShadow: 'none', zIndex: 10 }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className="D-DINCondensed.otf" style={{ flexGrow: 1 }}>
          
        </Typography>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem onClick={handleMenuClose} style={{ padding: '20px' }}>
              Home
            </MenuItem>
          </Link>
          <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem onClick={handleMenuClose} style={{ padding: '20px' }}>
              About
            </MenuItem>
          </Link>
          
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationMenu;